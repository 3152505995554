import React, {useState, useEffect, useContext, Fragment} from 'react';
import * as firebase from 'firebase/app';
import { AppContext } from '../App';
import FullScreenSpinner from '../components/FullScreenSpinner';

function RoomDetail() {
  const setError = useContext(AppContext).setError;

  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //Get assigned room details
    getRoomDetail();
    // eslint-disable-next-line
  }, []);

  const getRoomDetail = () => {
    const db = firebase.firestore();
    const roomRef = db.collection('rooms');
    roomRef.where("occupiedBy", "==", firebase.auth().currentUser.phoneNumber).get()
      .then((querySnapshot) => {
        let receivedRooms = []
        querySnapshot.forEach(function (doc) {
          const room = {
            id: doc.id,
            ...doc.data()
          }
          receivedRooms.push(room);
        });
        setRooms(receivedRooms);
      }).catch((error) => {
        console.log(error);
        setError("Failed to load details. Try again.");
      }).finally(() => setLoading(false));
  }

  if (loading) {
    return <FullScreenSpinner />
  }

  if (rooms.length < 1) {
    return (<h3>Can't find any rooms assigned to you</h3>)
  }

  return (
    <Fragment>
      <h2 className="display">Room Details</h2>
      <p><b>Room Number</b> {rooms[rooms.length - 1].roomNo}</p>
      <p><b>Floor</b> {rooms[rooms.length - 1].floor}</p>
      <p><b>Rent</b> {rooms[rooms.length - 1].rent}</p>
    </Fragment>
  );
}

export default RoomDetail;