import React, { useContext } from 'react';
import * as firebase from 'firebase/app';
import { Link } from 'react-router-dom';
import { AppContext } from '../App';

function Header() {
  const setError = useContext(AppContext).setError;

  const signOut = () => {
    firebase.auth().signOut().then(() => {
      console.log("Signed out");
    }).catch((error) => {
      console.log("Failed to sign out", error);
      setError("Failed to sign out. Try again.");
    })
  }

  return (
    <div className="d-flex justify-content-between align-items-center px-4 py-2 bg-dark text-white">
      <div className="d-flex flex-row align-items-center">
        <h3 className="display">User Dashboard</h3>
      </div>
      <div className="d-flex flex-row align-items-center">
        <Link className="p-2 text-light" to="/profile">Profile</Link>
        <Link to="/login" className="p-2 text-light" onClick={signOut}>Sign Out</Link>
      </div>
    </div>
  );
}

export default Header;