import React, { Fragment } from 'react';
import Header from '../components/Header';
import { Switch, Route } from 'react-router-dom';
import RoomDetail from './RoomDetail';

function Dashboard() {
  return (
    <Fragment>
      <Header />
      <div className="p-4">
        <Switch>
          <Route exact path="/">
            <RoomDetail />
          </Route>
        </Switch>
      </div>
    </Fragment>);
}

export default Dashboard;